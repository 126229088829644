import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-theme-material-ui';
import * as React from 'react';
import { BaseLayout } from '@/components/layouts/base_layout';
import { SEO } from '@/components/seo';

const AboutPage: React.FC = () => (
  <BaseLayout pageTitle="About Page">
    <SEO
      meta={[
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:site',
          content: '@_giftee',
        },
      ]}
    />
    <Link to="/">Back to Home</Link>
    <StaticImage
      alt="Clifford, a reddish-brown pitbull, posing on a couch and looking stoically at the camera"
      src="../images/icon.png"
    />
  </BaseLayout>
);

export default AboutPage;
